var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      ref: "fullnameModal",
      attrs: {
        persistent: "",
        "max-width": "500px",
        "content-class": "radius-15",
        "return-value": _vm.fullname
      },
      on: {
        "update:returnValue": function($event) {
          _vm.fullname = $event
        },
        "update:return-value": function($event) {
          _vm.fullname = $event
        }
      },
      model: {
        value: _vm.display,
        callback: function($$v) {
          _vm.display = $$v
        },
        expression: "display"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-2", attrs: { flat: "" } },
        [
          _c("v-card-title", { staticClass: "px-2" }, [
            _c("h2", { staticClass: "starling-h2" }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t("app.profile.my_starling.certificates.claim.title")
                  )
                )
              ])
            ])
          ]),
          _c("v-card-text", { staticClass: "px-2" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t("app.profile.my_starling.certificates.claim.line1")
                )
              )
            ])
          ]),
          _c(
            "v-card-text",
            { staticClass: "px-2" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      "persistent-hint": "",
                      type: "text",
                      rules: [
                        function(v) {
                          return (
                            (v && v.length > 0) ||
                            _vm.$t("common.errors.required")
                          )
                        },
                        function(v) {
                          return (
                            (v && v.trim().length >= 3) ||
                            _vm.$t("common.errors.min_param", { min: 3 })
                          )
                        },
                        function(v) {
                          return (
                            (v && v.trim().length <= 80) ||
                            _vm.$t("common.errors.max_param", { max: 80 })
                          )
                        }
                      ],
                      hint: _vm.$t(
                        "app.profile.my_starling.certificates.claim.hint"
                      )
                    },
                    model: {
                      value: _vm.currentFullname,
                      callback: function($$v) {
                        _vm.currentFullname = $$v
                      },
                      expression: "currentFullname"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "px-2" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t("app.profile.my_starling.certificates.claim.line2")
                )
              )
            ])
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    "align-center": "",
                    "justify-space-around": "",
                    "gap-xs-3": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", round: "" },
                          on: { click: _vm.onCancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.actions.cancel")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "primary-button",
                        {
                          attrs: { disabled: !_vm.valid },
                          on: { click: _vm.onDownload }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.actions.download")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }