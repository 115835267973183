



















































import { Certificate } from '@/scripts/store/modules/my_starling/types';
import Vue from 'vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'profile-certificate-claim',
  components: { PrimaryButton },
  props: {
    certificate: {
      type: Object as () => Certificate,
      required: true,
    },
  },
  data() {
    return {
      display: false,
      valid: false,
      fullname: '',
      currentFullname: '',
    };
  },
  watch: {
    fullname(newVal: string): void {
      this.$emit('claim', newVal);
    },
  },
  methods: {
    onActivate() {
      this.display = true;
    },
    onCancel() {
      this.display = false;
      this.valid = false;
      this.fullname = '';
      this.currentFullname = '';
    },
    onDownload() {
      (this.$refs.fullnameModal as any).save(this.currentFullname);
    },
  },
});
