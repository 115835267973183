








































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Certificate, CertificatePdf } from '@/scripts/store/modules/my_starling/types';
import ProfileCertificateClaim from '@/views/profile/partials/certificates/profile-certificate-claim.vue';

export default Vue.extend({
  name: 'profile-certificate',
  components: { ProfileCertificateClaim },
  props: {
    certificate: {
      type: Object as () => Certificate,
      required: true,
    },
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters({
      isNativePlatform: 'isNativePlatform',
    }),
    titleTrimmed(): string {
      const topic = this.certificate.title || '';
      const firstPart = topic.split(':')[0];
      return firstPart;
    },
  },
  methods: {
    onClick() {
      if (this.certificate.claimed) {
        this.download();
      } else {
        (this.$refs.claimDialog as any).onActivate();
      }
    },
    claim(fullname: string) {
      if (this.certificate.claimed) return;
      this.loading = true;
      this.$store.dispatch('myStarling/claimCertificate', { certificate: this.certificate, fullname })
        .then(() => {
          this.download();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    open(certificate: Certificate) {
      window.open(certificate.openUrl, '_blank');
    },
    download() {
      if (!this.certificate.downloadUrl) return;

      this.$store.dispatch('myStarling/downloadCertificate', this.certificate).then((pdf: CertificatePdf) => {
        if (this.isNativePlatform) {
          this.capacitorSaveFile(pdf.filename, pdf.blob);
        } else {
          const link = document.createElement('a');
          link.href = pdf.url;
          link.download = pdf.filename;
          link.target = '_blank';
          link.rel = 'noopener noreferrer';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    },
  },
});
