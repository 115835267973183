var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        [
          _c("v-hover", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var hover = ref.hover
                  return [
                    _c(
                      "v-card",
                      {
                        staticClass: "radius-15 clickable",
                        class: {
                          "elevation-4": !hover,
                          "elevation-12": hover
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.onClick($event)
                          }
                        }
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "pa-2" },
                          [
                            !_vm.loading
                              ? _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs10: "", sm11: "" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              overflow: "hidden",
                                              display: "block",
                                              "text-overflow": "ellipsis",
                                              "white-space": "nowrap"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(_vm.titleTrimmed) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-right",
                                        attrs: { xs2: "", sm1: "" }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "ma-0",
                                            attrs: {
                                              round: "",
                                              icon: "",
                                              small: ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "primary" } },
                                              [_vm._v("mdi-download")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { staticClass: "text-center" },
                                      [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            color: "primary",
                                            width: "3",
                                            indeterminate: ""
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      !_vm.certificate.claimed
        ? _c("profile-certificate-claim", {
            ref: "claimDialog",
            attrs: { certificate: _vm.certificate },
            on: { claim: _vm.claim }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }